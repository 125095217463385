<template>
  <div class="all-Infos" v-if="showId == 'all'">
    <img
      class="col-img"
      :src="farmInfo.photo ? uploadImgs + farmInfo.photo : defaultImgs"
    />
    <p class="col-title">{{ farmInfo.title }}</p>
    <div class="dress">
      <img :src="$imgOnlineUrl + '/index/icon11.png'" />
      <span>{{ farmInfo.address }}</span>
    </div>
    <div class="flex-box1">
      <div class="item">
        <div class="row-item">
          <span>{{ farmInfo.area }}</span
          ><span>亩农场</span>
        </div>
        <div class="row-item">
          <span>{{ farmCount }}</span
          ><span>块地块</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEquipmentCountApi } from "@/request/api";
import { mapState } from "vuex";
export default {
  props: {
    showId: {
      type: String,
      default: "",
    },
    weatherCount: {
      type: [Number, String],
      default: 0,
    },

    cameraList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      // 设备统计
      stat: {},
      defaultImgs: require("@/assets/image/configure/farm/icon8.png"),
    };
  },
  created() {
    this.getEquipmentCountApi();
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
      farmInfo: (state) => state.user.farmInfo,
    }),
    count() {
      let videoCount = 0;
      let num = this.cameraList.findIndex((item) => item.group === "视频监控");
      videoCount =
        num === -1
          ? 0
          : this.cameraList[
              this.cameraList.findIndex((item) => item.group === "视频监控")
            ].count;
      return videoCount;
    },
    farmCount() {
      let videoCount = 0;
      let num = this.cameraList.findIndex((item) => item.type === "plot");
      videoCount =
        num === -1
          ? 0
          : this.cameraList[
              this.cameraList.findIndex((item) => item.type === "plot")
            ].count;

      return videoCount;
    },
  },
  methods: {
    // 获取设备统计
    async getEquipmentCountApi() {
      const { code, data } = await getEquipmentCountApi({
        manor_id: this.farmID,
      });
      if (code === 200) {
        this.stat = data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.all-Infos {
  position: absolute;
  top: 106px;
  left: 78px;
  z-index: 999;
  width: 282px;
  // min-height: 342px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top: 6px solid #125397;
  background-color: #0f243d;
  display: flex;
  align-items: center;
  padding: 23px 4px;
  flex-direction: column;
  transition: all 0.5s;

  .col-img {
    width: 158px;
    height: 105px;
    margin-bottom: 22px;
  }

  .col-title {
    color: #fff;
    margin-bottom: 22px;
    font-size: 16px;
    font-weight: bold;
  }

  .dress {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 37px;

    img {
      width: 10px;
      height: 12px;
      margin-right: 5px;
    }

    span {
      color: #c8d6fa;
      font-size: 12px;
    }
  }

  .flex-box1 {
    width: 100%;
    display: flex;
    align-items: center;

    .item {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .row-item {
        position: relative;
        padding: 0 12px;
        display: flex;
        align-items: center;

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 2px;
          width: 1px;
          height: 14px;
          background-color: #134376;
        }

        &:last-of-type::after {
          display: none;
        }

        span {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #3e90e5;

          &:nth-of-type(2n) {
            font-size: 14px;
            font-weight: 400;
            margin-left: 5px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
